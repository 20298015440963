import * as React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, DatePicker, Radio, BackTop, Button, Spin, Select } from "antd";
import ImageUpload from "../components/ImageUpload";
import DocUpload from "../components/DocUpload";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { subjectSchema, GET_ORGS } from "./completeProfile";
import { showNotification } from "../utils/index";
import gql from "graphql-tag";
import moment from "moment";
import { GET_USER_DETAILS } from "./profile";
import { AuthContext } from "../components/authProvider";
import { isRequiredField } from "../utils/formik_helper";

const { Option } = Select;

export const EDIT_SUBJECT = gql`
  mutation editSubject($obj: subject_set_input!) {
    update_subject(where: {}, _set: $obj) {
      returning {
        name
        dob
        gender
        address
        phone
        email
        photo
        gov_id
        covidab_status
        covidag_status
        pass_issue_date
        affiliations {
          organisation {
            uuid
            org_name
            address
          }
        }
      }
    }
  }
`;

const EDIT_SUBJECT_AFFILIATIONS = gql`
  mutation editSubjectAffiliations($obj: [org_subject_insert_input!]!) {
    delete_org_subject(where: {}) {
      affected_rows
    }
    insert_org_subject(objects: $obj) {
      affected_rows
    }
  }
`;

export default function EditProfile(props) {
  const authState = React.useContext(AuthContext);
  const { loading, error, data: userData } = useQuery(GET_USER_DETAILS);
  const { loading: loadingOrgs, error: errorOrgs, data: orgData } = useQuery(GET_ORGS);

  const [editSubject] = useMutation(EDIT_SUBJECT, {
    refetchQueries: () => [
      {
        query: GET_USER_DETAILS
      }
    ]
  });
  const [editSubjectAffiliations] = useMutation(EDIT_SUBJECT_AFFILIATIONS);
  const dateFormat = "DD-MM-YYYY";

  const arrayCompare = (a1, a2) => {
    return a1.length === a2.length && a1.every(v => a2.includes(v));
  }

  const onSubmit = async values => {
    console.log(values);

    let old_affiliations = userData.user[0].subject.affiliations.map(a => a.organisation.uuid);
    let new_affiliations = values.affiliation.map(option => option.key);

    if (!arrayCompare(old_affiliations, new_affiliations)) {
      console.log("updating affiliations");
      let affiliations = [];
      new_affiliations.map(a => affiliations.push({org_id: a, subject_id: userData.user[0].subject.uuid}));
      // console.log(affiliations);

      await editSubjectAffiliations({
        variables: {
          obj: affiliations
        }
      });
    }

    let obj = {
      name: values.name.trim(),
      dob: values.dob,
      gender: values.gender,
      address: {
        line1: values.line1.trim(),
        line2: values.line2.trim(),
        city: values.city.trim(),
        state: values.state,
        pincode: values.pincode
      },
      email: values.email,
      phone: values.phone,
      photo: values.photo,
      gov_id: {
        id: values.gov_id,
        id_type: values.gov_id_type,
        id_no: values.gov_id_no
      }
    };
      
    await editSubject({
      variables: {
        obj: obj
      }
    });

    props.history.push("/profile");
    showNotification("success", "Success!", "Your profile has been updated");
  };

  if (loading || loadingOrgs) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);
  if (errorOrgs) console.log(errorOrgs);

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">Edit your profile</h2>
        <Formik
          initialValues={{
            name: userData.user[0].subject.name,
            dob: userData.user[0].subject.dob,
            gender: userData.user[0].subject.gender,
            email: userData.user[0].subject.email,
            phone: userData.user[0].subject.phone,
            line1: userData.user[0].subject.address.line1,
            line2: userData.user[0].subject.address.line2,
            city: userData.user[0].subject.address.city,
            state: userData.user[0].subject.address.state,
            pincode: userData.user[0].subject.address.pincode,
            photo: userData.user[0].subject.photo,
            gov_id: userData.user[0].subject.gov_id.id,
            gov_id_no: userData.user[0].subject.gov_id.id_no,
            gov_id_type: userData.user[0].subject.gov_id.id_type,
            affiliation: userData.user[0].subject.affiliations.map(a => ({key: a.organisation.uuid, value: a.organisation.org_name}))
          }}
          validationSchema={subjectSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            isSubmitting
          }) => (
            <Form>
              <p className="text-gray-700 font-semibold text-right">* Required</p>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Name {isRequiredField(subjectSchema, "name") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="name"
                  className={errors.name && touched.name ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Full name"
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Date of birth {isRequiredField(subjectSchema, "dob") && "*"}</label>
                <DatePicker
                  name="dob"
                  format={dateFormat}
                  size="large"
                  disabledDate={current => {
                    return !current || current.isAfter(moment().endOf("day")) || current.isBefore(moment("1900-01-01").startOf("day"));
                  }}
                  defaultValue={() => {
                    return moment(values.dob);
                  }}
                  className={
                    errors.dob && touched.dob ? "w-full border-red-500" : "w-full border-gray-400"
                  }
                  onChange={
                    (date, dateString) => setFieldValue("dob", moment(date).format("YYYY-MM-DD"))
                  }
                  onBlur={handleBlur}
                  value={values.dob !== "" ? moment(values.dob) : ""}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Gender {isRequiredField(subjectSchema, "gender") && "*"}</label>
                <Radio.Group
                  className="flex"
                  value={values.gender}
                  onChange={e => {
                    console.log(e);
                    setFieldValue("gender", e.target.value, true);
                  }}
                >
                  <Radio
                    className="block m-0 h-auto leading-8 text-gray-700"
                    value={"Male"}
                  >
                    Male
                  </Radio>
                  <Radio
                    className="block m-0 h-auto leading-8 text-gray-700"
                    value={"Female"}
                  >
                    Female
                  </Radio>
                  <Radio
                    className="block m-0 h-auto leading-8 text-gray-700"
                    value={"Third"}
                  >
                    Third
                  </Radio>
                </Radio.Group>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Phone number {isRequiredField(subjectSchema, "phone") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="phone"
                  disabled={authState.user.phoneNumber}
                  className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  prefix={"+91"}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Email address {isRequiredField(subjectSchema, "email") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="email"
                  disabled={authState.user.email}
                  className={errors.email && touched.email ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Address {isRequiredField(subjectSchema, "line1") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="line1"
                  className={errors.line1 && touched.line1 ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.line1}
                  placeholder="number, street, locality"
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="line1"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Address 2 {isRequiredField(subjectSchema, "line2") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="line2"
                  className={errors.line2 && touched.line2 ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.line2}
                  placeholder="door number, building name, floor"
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="line2"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">City {isRequiredField(subjectSchema, "city") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="city"
                  className={errors.city && touched.city ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">State {isRequiredField(subjectSchema, "state") && "*"}</label>
                <Select
                  name="state"
                  size="large"
                  placeholder="Select state"
                  className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400"}
                  onChange={(value) => setFieldValue("state", value, true)}
                  onBlur={handleBlur}
                  value={values.state}
                  showSearch
                  // optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                  <Option value="Andaman and Nicobar Islands">
                    Andaman and Nicobar Islands
                  </Option>
                  <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                  <Option value="Assam">Assam</Option>
                  <Option value="Bihar">Bihar</Option>
                  <Option value="Chandigarh">Chandigarh</Option>
                  <Option value="Chhattisgarh">Chhattisgarh</Option>
                  <Option value="Dadra and Nagar Haveli and Daman and Diu">
                    Dadra and Nagar Haveli and Daman and Diu
                  </Option>
                  <Option value="Delhi">Delhi</Option>
                  <Option value="Goa">Goa</Option>
                  <Option value="Gujarat">Gujarat</Option>
                  <Option value="Haryana">Haryana</Option>
                  <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                  <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                  <Option value="Jharkhand">Jharkhand</Option>
                  <Option value="Karnataka">Karnataka</Option>
                  <Option value="Kerala">Kerala</Option>
                  <Option value="Ladakh">Ladakh</Option>
                  <Option value="Lakshadweep">Lakshadweep</Option>
                  <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                  <Option value="Maharashtra	">Maharashtra </Option>
                  <Option value="Manipur">Manipur</Option>
                  <Option value="Meghalaya">Meghalaya</Option>
                  <Option value="Mizoram">Mizoram</Option>
                  <Option value="Nagaland">Nagaland</Option>
                  <Option value="Odisha">Odisha</Option>
                  <Option value="Puducherry">Puducherry</Option>
                  <Option value="Punjab">Punjab</Option>
                  <Option value="Rajasthan">Rajasthan</Option>
                  <Option value="Sikkim">Sikkim</Option>
                  <Option value="Tamil Nadu">Tamil Nadu</Option>
                  <Option value="Telangana">Telangana</Option>
                  <Option value="Tripura">Tripura</Option>
                  <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                  <Option value="Uttarakhand">Uttarakhand</Option>
                  <Option value="West Bengal">West Bengal</Option>
                </Select>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Pincode {isRequiredField(subjectSchema, "pincode") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="pincode"
                  className={
                    errors.pincode && touched.pincode ? "border-red-500" : "border-gray-400" 
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pincode}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="pincode"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Photo {isRequiredField(subjectSchema, "photo") && "*"}</label>
                <ImageUpload
                  accept="image/*"
                  multiple={false}
                  name={!!values.photo ? "Photo" : "photo"}
                  error={touched.photo ? errors.photo : null}
                  className={errors.photo && touched.photo ? "border-red-500" : "border-gray-400" }
                  value={values.photo}
                  headers={{authorization: `Bearer ${authState.token}`}}
                  onSuccess={data => {
                    setFieldValue("photo", data[0], true);
                    setFieldTouched("photo", true, true);
                  }}
                  onError={error => {
                    setFieldTouched("photo", true, false);
                    setFieldError("photo", error);
                  }}
                />
                {!values.photo && <p className="text-gray-800 text-xs italic">Upload your photo (max size less than 2 MB)</p>}
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="photo"
                    component="div"
                    className="text-red-500 text-center mb-2"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Government issued ID {isRequiredField(subjectSchema, "gov_id") && "*"}</label>
                <DocUpload
                  accept=".pdf"
                  multiple={false}
                  name={!!values.gov_id ? "ID" : "gov_id"}
                  error={touched.gov_id ? errors.gov_id : null}
                  className={errors.gov_id && touched.gov_id ? "border-red-500" : "border-gray-400" }
                  value={values.gov_id}
                  headers={{authorization: `Bearer ${authState.token}`}}
                  onSuccess={data => {
                    setFieldValue("gov_id", data[0], true);
                    setFieldTouched("gov_id", true, true);
                  }}
                  onError={error => {
                    console.log(error);
                    setFieldTouched("gov_id", true, false);
                    setFieldError("gov_id", error);
                  }}
                />
                {!values.gov_id && <p className="text-gray-800 text-xs italic">Upload a Government issued ID with legible details (max size less than 2 MB)</p>}
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gov_id"
                    component="div"
                    className="text-red-500 text-center mb-2"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">ID type {isRequiredField(subjectSchema, "gov_id_type") && "*"}</label>
                <Select
                  name="gov_id_type"
                  size="large"
                  placeholder="Select type"
                  className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400" }
                  onChange={value => {
                    setFieldValue("gov_id_type", value);
                  }}
                  onBlur={handleBlur}
                  value={values.gov_id_type}
                >
                  <Option value="Driving license">Driving license</Option>
                  <Option value="Passport">Passport</Option>
                  <Option value="Ration card">Ration card</Option>
                  <Option value="Voter ID">Voter ID</Option>
                  <Option value="Aadhaar">Aadhaar</Option>
                  <Option value="PAN">PAN</Option>
                </Select>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gov_id_type"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">ID number {isRequiredField(subjectSchema, "gov_id_no") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="gov_id_no"
                  className={errors.gov_id_no && touched.gov_id_no ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gov_id_no}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gov_id_no"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Affiliation(s) {isRequiredField(subjectSchema, "affiliation") && "*"}</label>
                <Select
                  name="affiliation"
                  mode="multiple"
                  size="large"
                  placeholder="Select organisation"
                  className={errors.affiliation && touched.affiliation ? "w-full border-red-500" : "w-full border-gray-400"}
                  onChange={(value, option) => {if (option.length <= 2) setFieldValue("affiliation", option, true);}}
                  onBlur={handleBlur}
                  value={values.affiliation.map(option => option.value)}
                  showSearch
                  // optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  tokenSeparators={[',']}
                  notFoundContent={"No organisations yet"}
                >
                  {orgData && orgData.organisation.map(org => <Option key={org.uuid} value={org.org_name}>{org.org_name}, {org.address.city}</Option>)}
                </Select>
                <p className="text-gray-800 text-xs italic">max 2 organisations</p>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="affiliation"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <Button
                size="middle"
                type="primary"
                className="bg-purple-700 hover:bg-purple-600 border-none h-10"
                shape="round"
                block={true}
                htmlType="submit"
                loading={isSubmitting}
              >
                Update profile
              </Button>
            </Form>
          )}
        </Formik>
      <BackTop />
    </div>
  );
}
