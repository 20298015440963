import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Spin, BackTop, Button, Table, Tooltip } from "antd";
import moment from "moment";
import { FileTextOutlined } from "@ant-design/icons";
import { GET_USER_DETAILS } from "./profile";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import { AuthContext } from "../components/authProvider";

export default function Tests(props) {
  const authState = React.useContext(AuthContext);
  const [testData, setTestData] = React.useState([]);
  const [count, setCount] = React.useState(null);
  const { loading, error } = useQuery(GET_USER_DETAILS, {
    onCompleted: data => {
      if (data.user[0].subject) {
        let tests = [];
        data.user[0].subject.labs.map(lab => lab.test_results.map(t => tests.push(t)));
        setTestData(tests);
        console.log(tests);
      }
    }
  });

  const downloadUrl = async (event, publicUrl) => {
    event.preventDefault();
    const url = await getDownloadSignedUrl(publicUrl, {headers: {authorization: `Bearer ${authState.token}`}});
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.download = "doc";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const columns = [
    {
      title: "Test date",
      key: "test_date",
      render: record => <p>{moment(record.test_date).format("DD-MM-YYYY")}</p>,
      sorter: (a, b) => moment(a.test_date).diff(moment(b.test_date)),
      sortDirections: ["ascend","descend"],
      defaultSortOrder: 'descend'
    },
    {
      title: "Test type",
      key: "test_type",
      filters: [
        { text: "COVID-19 Antibody", value: "COVIDAB" },
        { text: "COVID-19 Antigen", value: "COVIDAG" }
      ],
      onFilter: (value, record) => record.kit.test_type.includes(value),
      filterMultiple: false,
      render: record => {
        return <p>{record.kit.test_type === "COVIDAB" ? 
                    "COVID-19 Antibody" : 
                    "COVID-19 Antigen"}</p>;
      }
    },
    {
      title: "Result",
      key: "result",
      filters: [
        { text: "Positive", value: true },
        { text: "Negative", value: false }
      ],
      onFilter: (value, record) => record.result === value,
      render: record => {
        return <p>{record.result ? "Positive" : "Negative"}</p>;
      }
    },
    {
      title: "Test centre",
      key: "lab",
      render: record => <p className="capitalize">{record.lab_subject.lab.lab_name}, {record.lab_subject.lab.address.city}</p>,
      sorter: (a, b) => a.lab_subject.lab.lab_name.localeCompare(b.lab_subject.lab.lab_name),
      sortDirections: ["ascend","descend"],
    },
    {
      title: "Test report",
      key: "lab_report",
      render: record => {
        return (
          record.lab_report.report ?
          <Tooltip title="Open document">
              <Button
                icon={<FileTextOutlined className="align-middle"/>}
                shape="circle"
                type="primary"
                className="bg-gray-600 hover:bg-gray-500 border-none"
                onClick={(event) => downloadUrl(event, record.lab_report.report)}
              />
          </Tooltip> :
          <p>Not available</p>
        );
      }
    }
  ];

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">Your tests</h2>
      {testData.length ?
      <div className="my-4 mx-auto overflow-x-auto text-gray-700">
        <Table
          title={() => <p className="font-semibold">Showing {count === null ? testData.length : count} Entries</p>}
          dataSource={testData}
          columns={columns}
          locale={{emptyText:"No tests yet"}}
          onChange={(pagination, filters, sorter, extra) => setCount(extra.currentDataSource.length)}/>            
      </div> :
      <p className="text-gray-700 text-center">You have not tested yet</p>}
      <BackTop />
    </div>
  );
}