import { notification } from "antd";

export const showNotification = (type, heading = "", message = "") => {
  if (type === "success") {
    notification["success"]({
      message: `${heading}`,
      description: `${message}`
    });
  } else if (type === "error") {
    notification["error"]({
      message: `${heading}`,
      description: `${message}`
    });
  } else {
    notification["info"]({
      message: `${heading}`,
      description: `${message}`
    });
  }
};
