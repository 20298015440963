import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Spin, BackTop, Button } from "antd";
import moment from "moment";
import QRCode from "qrcode.react";
import { GET_USER_DETAILS } from "./profile";
// import { useMutation } from "@apollo/react-hooks";
// import { EDIT_SUBJECT } from "./editProfile";

const VERIFIER_DOMAIN = "https://gatekeeper.indiahealth.com";
const BODY_TEMPERATURE_THRESHOLD = 38;
const OXYGEN_SATURATION_THRESHOLD = 94;

export default function Status(props) {
  const { loading, error, data: userData } = useQuery(GET_USER_DETAILS);
  // const [editSubject, {loadingUpdate}] = useMutation(EDIT_SUBJECT);

  const Status = () => {
    if (userData.user[0].subject.covidag_status === null && 
        (userData.user[0].subject.covidab_status === null || 
         userData.user[0].subject.covidab_status)) {
      //Orange
      return <span className="bg-orange-500 rounded-md p-2">Get tested</span>;
    }
    else if (userData.user[0].subject.covidag_status) {
      //Red
      return <span className="bg-red-700 rounded-md p-2">Stay home</span>;
    }
    else if ((!userData.user[0].subject.covidag_status &&
              (userData.user[0].subject.covidab_status === null || 
               !userData.user[0].subject.covidab_status)) ||
            (userData.user[0].subject.covidag_status === null &&
             !userData.user[0].subject.covidab_status)) {
      //Blue
      if (userData.user[0].subject.latest_vital_signs.length &&
          (userData.user[0].subject.latest_vital_signs[0].body_temperature > BODY_TEMPERATURE_THRESHOLD || 
            userData.user[0].subject.latest_vital_signs[0].oxygen_saturation < OXYGEN_SATURATION_THRESHOLD) &&
          moment(userData.user[0].subject.latest_vital_signs[0].created_at).isAfter(moment(userData.user[0].subject.last_test_validated_at))) {
        return <span className="bg-orange-500 rounded-md p-2">Get tested again</span>;
      }
      else {
        return <span className="bg-blue-500 rounded-md p-2">You are OK</span>;
      }
    }
    else if (!userData.user[0].subject.covidag_status &&
             userData.user[0].subject.covidab_status) {
      //Green
      if (userData.user[0].subject.latest_vital_signs.length &&
          (userData.user[0].subject.latest_vital_signs[0].body_temperature > BODY_TEMPERATURE_THRESHOLD || 
            userData.user[0].subject.latest_vital_signs[0].oxygen_saturation < OXYGEN_SATURATION_THRESHOLD) &&
          moment(userData.user[0].subject.latest_vital_signs[0].created_at).isAfter(moment(userData.user[0].subject.last_test_validated_at))) {
        return <span className="bg-orange-500 rounded-md p-2">Get tested again</span>;
      }
      else {
        //Immune
        return <span className="bg-green-500 rounded-md p-2">Go ahead</span>;
      }
    }
  }; 
  
  const downloadQR = (event, elementId, imageName) => {
    event.preventDefault();
    const canvas = document.getElementById(elementId);
    if (canvas) {
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    }
  };

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">Your COVID-19 status</h2>
      <div className="text-center my-4">
        <div className="text-white text-lg font-bold uppercase my-4 md:ml-0">
            <Status/>
        </div>
        <div id="status-qr-canvas" className="p-2">
          <QRCode
            value={`${VERIFIER_DOMAIN}/immunity-status/${userData.user[0].subject.uuid}`}
            id="status-qr"
            className="mx-auto"
          />
          <Button
            type="link"
            onClick={(event) => downloadQR(event, "status-qr", "qr-immunity-status.png")}
          >
            Download QR
          </Button>
        </div>
        {/* <div 
          id="pass-help-text" 
          className="text-center text-gray-800"
          style={moment(userData.user[0].subject.pass_issue_date).isAfter(moment(userData.user[0].subject.last_test_validated_at)) ?
            {display: "block"} :
            {display: "none"}}>
          <p className="text-md"><span className="font-semibold">Immunity pass issued on </span>{" "}{moment(userData.user[0].subject.pass_issue_date).format("MMMM D, YYYY")}</p>
          <p className="text-sm text-gray-700">Check your immunity pass by scanning the QR code above</p>
        </div>
        {userData.user[0].subject.covidab_status && !userData.user[0].subject.covidag_status &&
        moment(userData.user[0].subject.pass_issue_date).isBefore(moment(userData.user[0].subject.last_test_validated_at)) &&
        <div className="mx-auto">
          <Button
            size="middle"
            type="primary"
            className="bg-purple-700 hover:bg-purple-600 border-none h-10"
            shape="round"
            onClick={async () => {
              await editSubject({
                variables: {
                  obj: {
                    pass_issue_date: moment().toISOString()
                  }
                }
              });
              document.getElementById("req-pass").style.display = "none";
              document.getElementById("pass-help-text").style.display = "block";
            }}
            loading={loadingUpdate}
            id="req-pass"
          >
            Request Immunity Pass
          </Button> 
        </div>} */}
      </div>
      <BackTop />
    </div>
  );
}