import axios from "axios";

export const S3_SIGNEDURL_API = "https://1bq35zlho7.execute-api.ap-south-1.amazonaws.com";
export const S3_BUCKET_URL = "https://capital-ihf-qb.s3.amazonaws.com/";

export const getUploadSignedUrl = async(fileName, fileType, options) => {
  let random_string = Math.random()
    .toString(36)
    .substr(2, 10);
  console.log("Get upload signed url");
  const fileKey = fileName + "_" + random_string;
  const response = await axios.get(
    `${S3_SIGNEDURL_API}/getUploadUrl?fileKey=${fileKey}&fileType=${fileType}`,
    options)
  return response.data;
};

export const getDownloadSignedUrl = async(publicUrl, options) => {
  const fileKey = publicUrl.replace(S3_BUCKET_URL,"");
  console.log("Get download signed url");
  const response = await axios.get(
    `${S3_SIGNEDURL_API}//getDownloadUrl?fileKey=${fileKey}`,
    options)
  return response.data;
};