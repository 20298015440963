import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Spin, Button, Tooltip, BackTop } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import moment from "moment";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import { AuthContext } from "../components/authProvider";
import anonymous_photo from "../assets/profile_image.png";

export const GET_USER_DETAILS = gql`
  query getUserDetails {
    user {
      email
      phone
      profile_completed
      subject {
        uuid
        name
        gender
        phone
        dob
        address
        email
        photo
        gov_id
        affiliations
        {
          organisation {
            uuid
            org_name
            address
          }
        }
        covidab_status
        covidag_status
        latest_vital_signs {
          created_at
          body_temperature
          oxygen_saturation
        }
        last_test_validated_at
        pass_issue_date
        created_at
        labs {
          test_results {
            lab_report
            test_date
            result
            kit {
              test_type
            }
            lab_subject {
              lab {
                lab_name
                address
              }
            }
          }
        }
      }
    }
  }
`;

export default function Profile(props) {
  const authState = React.useContext(AuthContext);
  const [photo, setPhoto] = React.useState(null);
  const { loading, error, data: userData } = useQuery(GET_USER_DETAILS, {
    onCompleted: async(data) => {
      if (data.user[0].subject.photo) {
        const photoUrl = await getDownloadSignedUrl(data.user[0].subject.photo, {headers: {authorization: `Bearer ${authState.token}`}});
        setPhoto(photoUrl);
      }
    }
  });

  const downloadUrl = async (event, publicUrl) => {
    event.preventDefault();
    const url = await getDownloadSignedUrl(publicUrl, {headers: {authorization: `Bearer ${authState.token}`}});
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.download = "doc";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (loading || (userData && userData.user[0].subject.photo && !photo)) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">Your profile</h2>

      <div className="my-4">
        <img 
          className="h-20 w-20 md:h-32 md:w-32 rounded-full mx-auto" 
          alt="patron_photo" 
          src={userData.user[0].subject.photo ?  photo : anonymous_photo}
        />
      </div>

      <div className="space-y-4">
        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">ID </p>{" "}
          <p>{userData.user[0].subject.uuid}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Member since </p>{" "}
          <p>{moment(userData.user[0].subject.created_at).format("MMMM D, YYYY")}</p>
        </div>
        
        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Name </p>{" "}
          <p className="capitalize">{userData.user[0].subject.name}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Date of birth </p>{" "}
          <p>{moment(userData.user[0].subject.dob).format("DD-MM-YYYY")}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Age </p>{" "}
          {moment().diff(moment(userData.user[0].subject.dob), "years") ? 
            <p>{moment().diff(moment(userData.user[0].subject.dob), "years")} years</p> : 
            <p>{moment().diff(moment(userData.user[0].subject.dob), "months")} months</p>}
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Gender </p>{" "}
          <p className="capitalize">{userData.user[0].subject.gender}</p>
        </div>

        {userData.user[0].subject.email &&
        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Email </p>{" "}
          <p>{userData.user[0].subject.email}</p>
        </div>}

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Phone </p>{" "}
          <p>{authState.user.phoneNumber ? userData.user[0].subject.phone : "+91" + userData.user[0].subject.phone}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Address </p>{" "}
          <p>
            {userData.user[0].subject.address.line1}{" "}{userData.user[0].subject.address.line2}{" "}
            {userData.user[0].subject.address.city}{" "}{userData.user[0].subject.address.state}{" "}-{" "}
            {userData.user[0].subject.address.pincode}
          </p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Government ID </p>{" "}
          <p>{userData.user[0].subject.gov_id.id_type} No. {userData.user[0].subject.gov_id.id_no} </p>{"  "}
          {userData.user[0].subject.gov_id.id &&
            <Tooltip title="Open document">
              <Button
                icon={<FileTextOutlined className="align-middle"/>}
                shape="circle"
                type="primary"
                className="bg-gray-600 hover:bg-gray-500 border-none"
                onClick={(event) => downloadUrl(event, userData.user[0].subject.gov_id.id)}
              />
            </Tooltip>}
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Affiliation(s) </p>{" "}
          <div>
            {userData.user[0].subject.affiliations.length ? 
              userData.user[0].subject.affiliations.map(a => <p>{a.organisation.org_name}, {a.organisation.address.city}</p>) : 
              <p>None</p>}
          </div>
        </div>

        {(!userData.user[0].subject.pass_issue_date || (userData.user[0].subject.pass_issue_date && 
          moment(userData.user[0].subject.pass_issue_date).isBefore(moment(userData.user[0].subject.last_test_validated_at)))) &&
          <Button
            size="middle"
            type="primary"
            className="bg-purple-700 hover:bg-purple-600 border-none h-10"
            shape="round"
            onClick={() => props.history.push("/edit-profile")}
          >
            Edit profile
          </Button>}
      </div>
      <BackTop />
    </div>
  );
}
