import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Row, Upload, message, Button, Modal } from "antd";
import axios from "axios";
import { getDownloadSignedUrl, getUploadSignedUrl } from "../../utils/s3_helper";
import "./style.css";

class DocUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      previewVisible: false,
      previewImage: ""
    };
  }

  uploadToS3 = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials
  }) => {
    const actionData = await getUploadSignedUrl(filename, file.type, {headers: headers});
    const signedUrl = actionData.signedUrl;
    const publicUrl = actionData.publicUrl;
    console.log("Recieved a signed request " + signedUrl);
    // Put the fileType in the headers for the upload
    var options = {
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
      headers: {
        'Content-Type': file.type
      }
    };
    await axios.put(signedUrl, file, options)
    .then(result => {
      console.log("Response from s3", result);
      onSuccess(publicUrl, file);
    })
    .catch(error => {
      console.log(error);
      onError(error);
    });
  };

  componentDidMount() {
    this.updateFileList();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      console.log("update");
      this.updateFileList();
    }
  }

  updateFileList = async () => {
    let fileList;
    if (this.props.multiple) {
      fileList = this.props.value.map(async (v, i) => {
        const downloadUrl = await getDownloadSignedUrl(v, {headers: this.props.headers});
        return({
          uid: i,
          name: `image-${i}`,
          response: v,
          url: downloadUrl
        });
      });
    } else {
      if (this.props.value) {
        const downloadUrl = await getDownloadSignedUrl(this.props.value, {headers: this.props.headers});
        fileList = [
          {
            uid: 0,
            name: this.props.name,
            response: this.props.value,
            url: downloadUrl
          }
        ];
      }
      else 
        fileList = [];
    }
    this.setState({ fileList });
  };

  handleChange = ({ file, fileList }) => {
    if (fileList.length) {
      let data = fileList.map(file => {
        file.url = file.response;
        return file.response;
      });
      this.props.onSuccess(data);
    } else {
      this.props.multiple
        ? this.props.onSuccess([])
        : this.props.onSuccess([""]);
    }
    this.setState({ fileList });
  };

  beforeUpload = file => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("Upload only PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File size must be less than 2MB!");
    }
    return isPdf && isLt2M;
  }

  handlePreview = async(file) => {
    this.setState({
      previewImage: file.url,
      previewVisible: true
    });
  };

  handleCancel = () => {
    this.setState({
      previewVisible: false,
      previewImage: ""
    });
  };

  render() {
    const { fileList } = this.state;
    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="middle">
          <Upload
            accept={this.props.accept || ""}
            className={this.props.className}
            name={this.props.name}
            headers={this.props.headers}
            customRequest={this.uploadToS3}
            fileList={fileList}
            onChange={this.handleChange}
            multiple={this.props.multiple}
            beforeUpload={this.beforeUpload}
            onPreview={this.handlePreview}
          >
            {!this.props.multiple && this.props.value ? 
              null :
              <Button
              >
                <UploadOutlined /> Upload
              </Button>}
          </Upload>
        </Row>
        <Modal
          visible={this.state.previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <embed
            alt="doc"
            type="application/pdf"
            style={{ width: "100%", height: "600px" }}
            src={this.state.previewImage}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default DocUpload;
