import React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Tabs, Button } from "antd";
import { myFirebase, AuthContext } from "../components/authProvider";
import firebase from "firebase/app";
import Countdown from "react-countdown";
import * as Yup from "yup";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { showNotification } from "../utils";

const { TabPane } = Tabs;

export default function Login(props) {

  const authState = React.useContext(AuthContext);
  if (authState.status === "authorised") {
    props.history.push("/profile");
  }
  const [phoneSigninState, setPhoneSigninState] = React.useState("signedOut");
  const [registerOption, setRegisterOption] = React.useState(null);
  const [signinOption, setSigninOption] = React.useState(null);
  const [confirmationResult, setConfirmationResult] = React.useState(null);
  const [phone, setPhone] = React.useState("");

  const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,}$/;
  const phoneRegExp = /^([1-9]{1})(\d{2,10})$/;
  const codeRegExp = /^(\d{1,6})$/;
  const EmailSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .ensure()
      .required("Required"),
    password: Yup.string()
      .ensure()
      .min(8, "Password must be atleast 8 characters")
      .matches(pwdRegExp, "Password does not meet criteria")
      .required("Required")
  });
  const PhoneSchema = Yup.object({
    phone: Yup.string()
      .ensure()
      .matches(phoneRegExp, "Phone number must be 10 digits, no special characters")
      .length(10, "Phone number must be exactly 10 digits")
      .required("Required")
  });
  const CodeSchema = Yup.object({
    code: Yup.string()
      .ensure()
      .matches(codeRegExp, "Code must be 6 digits, no special characters")
      .length(6, "Code must be exactly 6 digits")
      .required("Required")
  });

  const handleSignup = (values, { setStatus, resetForm } ) => {
    // console.log(values);
    setStatus("");
    let reg;
    if (registerOption === "email")
      reg = {
        email: values.email,
        password: values.password,
        accountRole: "subject"
      };
    else if (registerOption === "phone")
      reg = {
        phone: "+91" + values.phone,
        accountRole: "subject"
      }
    else 
      return;
    var registerUser = myFirebase.functions().httpsCallable('registerUser');
    registerUser(reg).then(res => {
        console.log("res", res);
        if (registerOption === "email") {
          myFirebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
          .then(async res => {
            console.log(res);
            var user = myFirebase.auth().currentUser;
            // await user.getIdToken(true);
            user
            .sendEmailVerification()//{actionCodeSettings:{url:"/profile"}})
            .then(function() {
              // Email sent.
              console.log("email sent");
              showNotification("success", "Success!", "Verification email sent. Please check your email (including spam folder)");
              myFirebase
                .auth()
                .signOut()
                .then(function() {
                    // Sign-out successful.
                    console.log("Successful");
                    resetForm();
                })
                .catch(function(error) {
                    console.log("error", error);
                    resetForm();
                    // An error happened.
                });
            });
          })
          .catch(function(error) {
            // Handle Errors here.
            console.log(error);
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/user-not-found') {
              resetForm();
              setStatus('Error registering email. Please try again');
            }
            else  {
              resetForm();
              setStatus(errorMessage);
            }
          });
        }
        else {
          resetForm();
          showNotification("success", "Success!", "Phone number registered. You may login now");
        }
      })
      .catch(function(error) {
        console.log(error);
        console.log(error.code);
        console.log(error.message);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "already-exists") {
          resetForm();
          setStatus(`${registerOption} already registered. Please login instead`);
        }
        else  {
          resetForm();
          setStatus(errorMessage);
        }
      });
  };

  const onEmailSignIn = (values, { resetForm, setStatus } ) => {
    setStatus("");
    authState.setStatus("authenticating");
    myFirebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(async res => {
        // console.log("res", res);
        if (res.user.emailVerified === false) {
          myFirebase
          .auth()
          .signOut()
          .then(function() {
            console.log("Successful logout");
            resetForm();
            setStatus("Please verify your email before trying to login");
          })
          .catch(function(error) {
            console.log("error", error);
            resetForm();
            setStatus("Please verify your email before trying to login");
          });
        }
        else {
          authState.setStatus("authenticated");
          setTimeout(() => {
            props.history.push("/profile");
          }, 2000);
        }
      })
      .catch(function(error) {
        // Handle Errors here.
        console.log(error);
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/wrong-password") {
          resetForm();
          setStatus('Wrong password');
        }
        else if (errorCode === 'auth/user-not-found') {
          resetForm();
          setStatus('Email not registered yet. Please register first');
        }
        else if (errorCode === 'auth/network-request-failed') {
          resetForm();
          setStatus('Network error. Please try again');
        }
        else  {
          resetForm();
          setStatus(errorMessage);
        }
      });
  };

  const onPhoneSignIn = (values, { setStatus, setSubmitting, resetForm } ) => {
    setStatus("");
    var checkUser = myFirebase.functions().httpsCallable('checkUser');
    checkUser({phone: phone}).then(res => {
      console.log("res", res);
      setPhoneSigninState("signingIn");
      authState.setStatus("authenticating");

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible"
        }
      );
      const appVerifier = window.recaptchaVerifier;
      appVerifier.render().then(function(widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
      myFirebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then(function(confirmationResult) {
        console.log("Success");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(confirmationResult);
        setPhoneSigninState("codeSent");
        setSubmitting(false);
      })
      .catch(function(error) {
        console.log("Error:" + error.code);
        showNotification("error", "Error!", "Something went wrong. Please try again");
        setPhoneSigninState("signedOut");
      });
    })
    .catch(function(error) {
      // Handle Errors here.
      console.log(error);
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode === 'not-found') {
        resetForm();
        setStatus('Phone not registered yet. Please register first');
      }
      else  {
        resetForm();
        setStatus(errorMessage);
      }
    });
  };

  const onVerifyCodeSubmit = (values, { setStatus, setSubmitting, resetForm } ) => {
    setStatus("");
    confirmationResult
      .confirm(values.code)
      .then(function(result) {
        // User signed in successfully.
        authState.setStatus("authenticated");
        setTimeout(() => {
          setPhoneSigninState("signedIn");
          props.history.push("/profile");
        }, 2000);
      })
     .catch(function(error) {
        // User couldn't sign in (bad verification code?)
        console.error("Error while checking the verification code", error);
        resetForm({values: {phone: values.phone, code: ""}});
        setStatus("Invalid verification code. Try again");
        setSubmitting(false);
      });
  };

  const onResendCode = (event) => {
    event.preventDefault();
    setConfirmationResult(null);
    const appVerifier = window.recaptchaVerifier;
    myFirebase
     .auth()
     .signInWithPhoneNumber(phone, appVerifier)
     .then(function(confirmationResult) {
        console.log("Success");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(confirmationResult);
        setPhoneSigninState("codeResent");
      })
     .catch(function(error) {
        console.log("Error:" + error.code);
      });
  };

  return (
    <div className="p-8 md:p-16 mx-auto">
      <Tabs defaultActiveKey="signin" centered>
        <TabPane tab={<span className="text-teal-500 text-lg font-semibold">Register</span>} key="signup">
          {!registerOption &&
          <div className="my-4 space-y-4 text-center">
            <Button
              size="middle"
              type="primary"
              className="bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64"
              shape="round"
              block={true}
              onClick={() => {
                setRegisterOption("phone");
              }}
            >
              <PhoneOutlined style={{verticalAlign: "middle"}} rotate="90"/> Register with phone
            </Button>
            <br/>
            <Button
              size="middle"
              type="primary"
              className="bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64"
              shape="round"
              block={true}
              onClick={() => {
                setRegisterOption("email");
              }}
            >
              <MailOutlined style={{verticalAlign: "middle"}}/> Register with email
            </Button>
          </div>}
          {!!registerOption &&
          <div className="my-1">
            <Formik
              initialValues={{
                phone: "",
                email: "",
                password: ""
              }}
              validationSchema={registerOption === "email" ? EmailSchema : PhoneSchema}
              onSubmit={handleSignup}
              enableReinitialize={true}
            >
              {({ values, errors, touched, handleChange, handleBlur, status, isSubmitting }) => (
                <Form>
                  <div className={registerOption === "email" ? "flex flex-col" : "hidden"}>
                    <label className="text-gray-700 font-semibold">Email address</label>
                    <Input
                      size="large"
                      type="text"
                      name="email"
                      className={
                        errors.email && touched.email ? "border-red-500" : "border-gray-400"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      disabled={registerOption !== "email"}
                    />
                    <div
                      style={{
                        minHeight: 21
                      }}
                    >
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className={registerOption === "email" ? "flex flex-col" : "hidden"}>
                    <label className="text-gray-700 font-semibold">Password</label>
                    <Input
                      size="large"
                      type="password"
                      name="password"
                      className={
                        errors.password && touched.password
                          ? "border-red-500"
                          : "border-gray-400"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      disabled={registerOption !== "email"}
                    />
                    <div
                      style={{
                        minHeight: 21
                      }}
                    >
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  {registerOption === "email" && <p className="text-gray-700 text-xs italic">Atleast one uppercase, one lowercase and a number, no special characters</p>}
                  <div className={registerOption === "phone" ? "flex flex-col" : "hidden"}>
                    <label className="text-gray-700 font-semibold">Phone number</label>
                    <Input
                      size="large"
                      type="text"
                      name="phone"
                      className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400" }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      prefix={"+91"}
                      disabled={registerOption !== "phone"}
                    />
                    <div
                      style={{
                        minHeight: 21
                      }}
                    >
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      minHeight: 21
                    }}
                  >
                    {!!status && <p className="text-red-500">{status}</p>}
                  </div>
                  {/* {registerOption === "phone" && <p className="text-gray-700 text-xs italic">By signing in, verification code is sent by SMS. Standard SMS rates may apply.</p>} */}
                  <div className="my-4 space-y-4 md:space-x-4 text-center">
                    <Button
                      size="middle"
                      type="primary"
                      className="bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64"
                      shape="round"
                      block={true}
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                      Register
                    </Button>
                    <Button
                      size="middle"
                      type="default"
                      className="bg-indigo-200 md:bg-indigo-300 hover:bg-indigo-100 md:hover:bg-indigo-200 text-gray-700 border-none shadow-md h-10 md:w-64"
                      shape="round"
                      block={true}
                      onClick={() => {
                        setRegisterOption(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>}
        </TabPane>

        <TabPane tab={<span className="text-teal-500 text-lg font-semibold">Login</span>} key="signin">
        {!signinOption &&
          <div className="my-4 space-y-4 text-center">
            <Button
              size="middle"
              type="primary"
              className="bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64"
              shape="round"
              block={true}
              onClick={() => {
                setSigninOption("phone");
              }}
            >
              <PhoneOutlined style={{verticalAlign: "middle"}} rotate="90"/> Login with phone
            </Button>
            <br />
            <Button
              size="middle"
              type="primary"
              className="bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64"
              shape="round"
              block={true}
              onClick={() => {
                setSigninOption("email");
              }}
            >
              <MailOutlined style={{verticalAlign: "middle"}}/> Login with email
            </Button>
          </div>}
          {!!signinOption &&
          <div className="my-1">
            <Formik
              initialValues={{
                email: "",
                password: "",
                phone: "",
                code: ""
              }}
              validationSchema={signinOption === "email" ? EmailSchema : (phoneSigninState === "signedOut") ? PhoneSchema : CodeSchema}
              onSubmit={signinOption === "email" ? onEmailSignIn : (phoneSigninState === "signedOut") ? onPhoneSignIn : onVerifyCodeSubmit}
              enableReinitialize={true}
            >
              {({ values, errors, touched, handleChange, handleBlur, setFieldValue, status, isSubmitting }) => (
                <Form>
                  <div className={signinOption === "email" ? "flex flex-col" : "hidden"}>
                    <label className="text-gray-700 font-semibold">Email address</label>
                    <Input
                      size="large"
                      type="text"
                      name="email"
                      className={
                        errors.email && touched.email ? "border-red-500" : "border-gray-400" 
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      
                      disabled={signinOption !== "email"}
                    />
                    <div
                      style={{
                        minHeight: 21
                      }}
                    >
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className={signinOption === "email" ? "flex flex-col" : "hidden"}>
                    <label className="text-gray-700 font-semibold">Password</label>
                    <Input
                      size="large"
                      type="password"
                      name="password"
                      className={
                        errors.password && touched.password
                          ? "border-red-500"
                          : "border-gray-400"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      
                      disabled={signinOption !== "email"}
                    />
                    <div
                      style={{
                        minHeight: 21
                      }}
                    >
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <Button
                      type="link"
                      onClick={() => props.history.push("/forgot-password")}
                      style={signinOption === "email" ? {display:"block"} : {display:"none"}}
                      className="-ml-4"
                  >
                    Forgot password
                  </Button>
                  <div className={(signinOption === "phone") && ((phoneSigninState === "signedOut") || (phoneSigninState === "signingIn")) ? "flex flex-col" : "hidden"}>
                    <label className="text-gray-700 font-semibold">Phone number</label>
                    <Input
                      size="large"
                      type="text"
                      name="phone"
                      className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400" }
                      onChange={e => {
                        setFieldValue("phone", e.target.value, true);
                        setPhone("+91" + e.target.value);}}
                      onBlur={handleBlur}
                      value={values.phone}
                      
                      prefix={"+91"}
                      disabled={(signinOption !== "phone") && ((phoneSigninState !== "signedOut") && (phoneSigninState !== "signingIn"))}
                    />
                    <div
                      style={{
                        minHeight: 21
                      }}
                    >
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  {(signinOption === "phone") && ((phoneSigninState === "signedOut") || (phoneSigninState === "signingIn")) && 
                    <p className="text-gray-700 text-xs italic">By logging in, verification code is sent by SMS. Standard SMS rates may apply.</p>}
                  <div className={(signinOption === "phone") && ((phoneSigninState === "codeSent") || (phoneSigninState === "codeResent")) ? "flex flex-col" : "hidden"}>
                    <label className="text-gray-700 font-semibold">Verification code sent to {phone} </label>
                    <Input
                      size="large"
                      type="text"
                      name="code"
                      className={
                        errors.code && touched.code
                          ? "border-red-500"
                          : "border-gray-400"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                      
                      disabled={(signinOption !== "phone") && ((phoneSigninState !== "codeSent") && (phoneSigninState !== "codeResent"))}
                    />
                    <div
                      style={{
                        minHeight: 21
                      }}
                    >
                      <ErrorMessage
                        name="code"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <Countdown
                      date={Date.now() + 10000}
                      autoStart={false}
                      renderer={({seconds, completed}) => {
                        if (phoneSigninState === "codeSent") {
                          if (completed)
                            return (
                            <Button
                              id="resend-code-button"
                              type="link"
                              onClick={(event) => onResendCode(event)}
                            >
                              Resend code
                            </Button>);
                          else
                            return <span>Resend code in {seconds}</span>;
                        }
                        else
                          return null;
                      }}
                      ref={(countdown) => {
                        if (countdown) {
                          const countdownApi = countdown.getApi();
                          if (phoneSigninState === "codeSent") {
                            // countdown.setState({timeDelta: 20000});
                            countdownApi.start();
                          }
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      minHeight: 21
                    }}
                  >
                    {!!status && <p className="text-red-500">{status}</p>}
                  </div>
                  <div id="recaptcha-container"></div>
                  <div className="my-4 space-y-4 md:space-x-4 text-center">
                    <Button
                      size="middle"
                      type="primary"
                      className={signinOption === "email" ? "bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64" : "hidden"}
                      shape="round"
                      block={true}
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                      Login
                    </Button>
                    <Button
                      size="middle"
                      type="primary"
                      className={(signinOption === "phone") && ((phoneSigninState === "signedOut") || (phoneSigninState === "signingIn")) ? "bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64" : "hidden"}
                      shape="round"
                      block={true}
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                      Login
                    </Button>
                    <Button
                      size="middle"
                      type="primary"
                      className={(signinOption === "phone") && (phoneSigninState === "codeSent" || phoneSigninState === "codeResent") ? "bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64" : "hidden"}
                      shape="round"
                      block={true}
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                      Verify
                    </Button>
                    <Button
                      size="middle"
                      type="default"
                      className="bg-indigo-200 md:bg-indigo-300 hover:bg-indigo-100 md:hover:bg-indigo-200 text-gray-700 border-none shadow-md h-10 md:w-64"
                      shape="round"
                      block={true}
                      onClick={() => {
                        setSigninOption(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>}
        </TabPane>
      </Tabs>
    </div>
  );
}