import * as React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Checkbox, DatePicker, Radio, BackTop, Button, Spin, Select, Modal } from "antd";
import ImageUpload from "../components/ImageUpload";
import DocUpload from "../components/DocUpload";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Yup from "yup";
import moment from "moment";
import { showNotification } from "../utils/index";
import { AuthContext } from "../components/authProvider";
import { isRequiredField } from "../utils/formik_helper";

const { Option } = Select;

const ADD_SUBJECT = gql`
  mutation addSubject($obj: subject_insert_input!) {
    insert_subject_one(object: $obj) {
      uuid
      created_at
      name
      email
      phone
      gender
      dob
      gov_id
      affiliations {
        organisation {
          org_name
          address
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($obj: user_set_input!) {
    update_user(where: {}, _set: $obj) {
      returning {
        id
        name
        phone
        email
        user_type
        user_type_id
        profile_completed
      }
    }
  }
`;

export const GET_ORGS = gql`
  query getOrgs {
    organisation {
      uuid
      org_name
      tax_id
      address
    }
  }
`;

const phoneRegExp = /^([1-9]{1})(\d{2,10})$/;
const pincodeRegExp = /^([1-9]{1})(\d{2,6})$/;
export const subjectSchema = Yup.object({
  name: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required"),
  dob: Yup.string()
    .ensure()
    .required("Required"),
  gender: Yup.string()
    .ensure()
    .required("Required"),
  phone: Yup.string()
    .ensure()
    .matches(phoneRegExp, "Only numeric, no special characters")
    .length(10, "Phone number must be exactly 10 digits")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .ensure(),
    // .required("Required"),
  line1: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .required("Required"),
  city: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required"),
  state: Yup.string()
    .ensure()
    .required("Required"),
  pincode: Yup.string()
    .ensure()
    .matches(pincodeRegExp, "Only numeric, no special characters")
    .length(6, "Pincode must be exactly 6 digits")
    .required("Required"),
  // photo: Yup.string()
  //   .ensure()
  //   .required("Required"),
  // gov_id: Yup.string()
  //   .ensure()
  //   .required("Required"),
  gov_id_no: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .required("Required"),
  gov_id_type: Yup.string()
    .ensure()
    .required("Required")
});

export default function CompleteProfile(props) {
  const authState = React.useContext(AuthContext);
  const { loadingOrgs, errorOrgs, data:orgData } = useQuery(GET_ORGS);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [tocVisible, setTocVisible] = React.useState(false);

  const [addSubject] = useMutation(ADD_SUBJECT, 
    {onCompleted: async (data) => {
      await updateUserMutation({
        variables: {
          obj: {
            name: data.insert_subject_one.name,
            user_type: "Subject",
            user_type_id: data.insert_subject_one.uuid,
            profile_completed: true
          }
        }
      });
      authState.setProfileIncomplete(false);
      props.history.push("/profile");
      showNotification("success", "Success!", "Your profile is complete");
    }
  });

  const dateFormat = "DD-MM-YYYY";

  const onSubmit = async values => {
    console.log(values);

    if (values.consent === false) {
      showNotification(
        "error",
        "Error!",
        "Please agree to the terms and conditions to proceed"
      );
      return;
    }

    let obj = {
      name: values.name.trim(),
      dob: values.dob,
      gender: values.gender,
      address: {
        line1: values.line1.trim(),
        line2: values.line2.trim(),
        city: values.city.trim(),
        state: values.state,
        pincode: values.pincode
      },
      email: values.email,
      phone: values.phone,
      photo: values.photo,
      gov_id: {
        id: values.gov_id,
        id_type: values.gov_id_type,
        id_no: values.gov_id_no
      }
    };
       
    if (values.affiliation.length)
      obj.affiliations = {
        data: values.affiliation.map(option => ({org_id: option.key}))
      };
    await addSubject({
      variables: {
        obj: obj
      }
    });
  };

  if (!authState.profileIncomplete) props.history.push("/profile");

  if (loadingOrgs) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (errorOrgs) console.log(errorOrgs);

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">Complete your profile to proceed</h2>
        <Formik
          initialValues={{
            name: "",
            dob: "",
            gender: "",
            phone: authState.user.phoneNumber ? authState.user.phoneNumber.replace("+91","") : "",
            email: authState.user.email ? authState.user.email : "",
            consent: false,
            line1: "",
            line2: "",
            city: "",
            state: "",
            pincode: "",
            photo: "",
            gov_id: "",
            gov_id_no: "",
            gov_id_type: "",
            affiliation: []
          }}
          validationSchema={subjectSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            isSubmitting
          }) => (
            <Form>
              <p className="text-gray-700 font-semibold text-right">* Required</p>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Name {isRequiredField(subjectSchema, "name") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="name"
                  className={errors.name && touched.name ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Full name"
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Date of birth {isRequiredField(subjectSchema, "dob") && "*"}</label>
                <DatePicker
                  name="dob"
                  format={dateFormat}
                  size="large"
                  disabledDate={current => {
                    return !current || current.isAfter(moment().endOf("day")) || current.isBefore(moment("1900-01-01").startOf("day"));
                  }}
                  className={
                    errors.dob && touched.dob ? "w-full border-red-500" : "w-full border-gray-400"
                  }
                  onChange={
                    (date, dateString) => setFieldValue("dob", moment(date).format("YYYY-MM-DD"))
                  }
                  onBlur={handleBlur}
                  value={values.dob !== "" ? moment(values.dob) : ""}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Gender {isRequiredField(subjectSchema, "gender") && "*"}</label>
                <Radio.Group
                  className="flex"
                  value={values.gender}
                  onChange={e => {
                    console.log(e);
                    setFieldValue("gender", e.target.value, true);
                  }}
                >
                  <Radio
                    className="block m-0 h-auto leading-8 text-gray-700"
                    value={"Male"}
                  >
                    Male
                  </Radio>
                  <Radio
                    className="block m-0 h-auto leading-8 text-gray-700"
                    value={"Female"}
                  >
                    Female
                  </Radio>
                  <Radio
                    className="block m-0 h-auto leading-8 text-gray-700"
                    value={"Third"}
                  >
                    Third
                  </Radio>
                </Radio.Group>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Phone number {isRequiredField(subjectSchema, "phone") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="phone"
                  disabled={authState.user.phoneNumber}
                  className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  prefix={"+91"}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Email address {isRequiredField(subjectSchema, "email") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="email"
                  disabled={authState.user.email}
                  className={errors.email && touched.email ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Address {isRequiredField(subjectSchema, "line1") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="line1"
                  className={errors.line1 && touched.line1 ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.line1}
                  placeholder="number, street, locality"
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="line1"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Address 2 {isRequiredField(subjectSchema, "line2") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="line2"
                  className={errors.line2 && touched.line2 ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.line2}
                  placeholder="door number, building name, floor"
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="line2"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">City {isRequiredField(subjectSchema, "city") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="city"
                  className={errors.city && touched.city ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">State {isRequiredField(subjectSchema, "state") && "*"}</label>
                <Select
                  name="state"
                  size="large"
                  placeholder="Select state"
                  className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400"}
                  onChange={(value) => setFieldValue("state", value, true)}
                  onBlur={handleBlur}
                  value={values.state}
                  showSearch
                  // optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                  <Option value="Andaman and Nicobar Islands">
                    Andaman and Nicobar Islands
                  </Option>
                  <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                  <Option value="Assam">Assam</Option>
                  <Option value="Bihar">Bihar</Option>
                  <Option value="Chandigarh">Chandigarh</Option>
                  <Option value="Chhattisgarh">Chhattisgarh</Option>
                  <Option value="Dadra and Nagar Haveli and Daman and Diu">
                    Dadra and Nagar Haveli and Daman and Diu
                  </Option>
                  <Option value="Delhi">Delhi</Option>
                  <Option value="Goa">Goa</Option>
                  <Option value="Gujarat">Gujarat</Option>
                  <Option value="Haryana">Haryana</Option>
                  <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                  <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                  <Option value="Jharkhand">Jharkhand</Option>
                  <Option value="Karnataka">Karnataka</Option>
                  <Option value="Kerala">Kerala</Option>
                  <Option value="Ladakh">Ladakh</Option>
                  <Option value="Lakshadweep">Lakshadweep</Option>
                  <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                  <Option value="Maharashtra	">Maharashtra </Option>
                  <Option value="Manipur">Manipur</Option>
                  <Option value="Meghalaya">Meghalaya</Option>
                  <Option value="Mizoram">Mizoram</Option>
                  <Option value="Nagaland">Nagaland</Option>
                  <Option value="Odisha">Odisha</Option>
                  <Option value="Puducherry">Puducherry</Option>
                  <Option value="Punjab">Punjab</Option>
                  <Option value="Rajasthan">Rajasthan</Option>
                  <Option value="Sikkim">Sikkim</Option>
                  <Option value="Tamil Nadu">Tamil Nadu</Option>
                  <Option value="Telangana">Telangana</Option>
                  <Option value="Tripura">Tripura</Option>
                  <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                  <Option value="Uttarakhand">Uttarakhand</Option>
                  <Option value="West Bengal">West Bengal</Option>
                </Select>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Pincode {isRequiredField(subjectSchema, "pincode") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="pincode"
                  className={
                    errors.pincode && touched.pincode ? "border-red-500" : "border-gray-400" 
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pincode}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="pincode"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Photo {isRequiredField(subjectSchema, "photo") && "*"}</label>
                <ImageUpload
                  accept="image/*"
                  multiple={false}
                  name={!!values.photo ? "Photo" : "photo"}
                  error={touched.photo ? errors.photo : null}
                  className={errors.photo && touched.photo ? "border-red-500" : "border-gray-400" }
                  value={values.photo}
                  headers={{authorization: `Bearer ${authState.token}`}}
                  onSuccess={data => {
                    setFieldValue("photo", data[0], true);
                    setFieldTouched("photo", true, true);
                  }}
                  onError={error => {
                    setFieldTouched("photo", true, false);
                    setFieldError("photo", error);
                  }}
                />
                {!values.photo && <p className="text-gray-800 text-xs italic">Upload your photo (max size less than 2 MB)</p>}
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="photo"
                    component="div"
                    className="text-red-500 text-center mb-2"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Government issued ID {isRequiredField(subjectSchema, "gov_id") && "*"}</label>
                <DocUpload
                  accept=".pdf"
                  multiple={false}
                  name={!!values.gov_id ? "ID" : "gov_id"}
                  error={touched.gov_id ? errors.gov_id : null}
                  className={errors.gov_id && touched.gov_id ? "border-red-500" : "border-gray-400" }
                  value={values.gov_id}
                  headers={{authorization: `Bearer ${authState.token}`}}
                  onSuccess={data => {
                    setFieldValue("gov_id", data[0], true);
                    setFieldTouched("gov_id", true, true);
                  }}
                  onError={error => {
                    console.log(error);
                    setFieldTouched("gov_id", true, false);
                    setFieldError("gov_id", error);
                  }}
                />
                {!values.gov_id && <p className="text-gray-800 text-xs italic">Upload a Government issued ID with legible details (max size less than 2 MB)</p>}
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gov_id"
                    component="div"
                    className="text-red-500 text-center mb-2"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">ID type {isRequiredField(subjectSchema, "gov_id_type") && "*"}</label>
                <Select
                  name="gov_id_type"
                  size="large"
                  placeholder="Select type"
                  className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400" }
                  onChange={value => {
                    setFieldValue("gov_id_type", value);
                  }}
                  onBlur={handleBlur}
                  value={values.gov_id_type}
                >
                  <Option value="Driving license">Driving license</Option>
                  <Option value="Passport">Passport</Option>
                  <Option value="Ration card">Ration card</Option>
                  <Option value="Voter ID">Voter ID</Option>
                  <Option value="Aadhaar">Aadhaar</Option>
                  <Option value="PAN">PAN</Option>
                </Select>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gov_id_type"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">ID number {isRequiredField(subjectSchema, "gov_id_no") && "*"}</label>
                <Input
                  size="large"
                  type="text"
                  name="gov_id_no"
                  className={errors.gov_id_no && touched.gov_id_no ? "border-red-500" : "border-gray-400" }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gov_id_no}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="gov_id_no"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold">Affiliation(s) {isRequiredField(subjectSchema, "affiliation") && "*"}</label>
                <Select
                  name="affiliation"
                  mode="multiple"
                  size="large"
                  placeholder="Select organisation"
                  className={errors.affiliation && touched.affiliation ? "w-full border-red-500" : "w-full border-gray-400"}
                  onChange={(value, option) => {if (option.length <= 2) setFieldValue("affiliation", option, true);}}
                  onBlur={handleBlur}
                  value={values.affiliation.map(option => option.value)}
                  showSearch
                  // optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  tokenSeparators={[',']}
                  notFoundContent={"No organisations yet"}
                >
                  {orgData && orgData.organisation.map(org => <Option key={org.uuid} value={org.org_name}>{org.org_name}, {org.address.city}</Option>)}
                </Select>
                <p className="text-gray-800 text-xs italic">max 2 organisations</p>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="affiliation"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <Checkbox
                  name="consent"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.consent}
                  checked={values.consent}
                  className="text-white"
                >
                  I agree to the
                  <Button
                    type="link"
                    onClick={() => setTocVisible(true)}
                    className="-m-3 text-white hover:text-gray-800 focus:text-gray-800 italic"
                  >
                    Terms & Conditions
                  </Button>
                </Checkbox>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="consent"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div> 
              <Button
                size="middle"
                type="primary"
                className="bg-purple-700 hover:bg-purple-600 border-none h-10"
                shape="round"
                block={true}
                htmlType="submit"
                loading={isSubmitting}
              >
                Complete profile
              </Button>
            </Form>
          )}
        </Formik>
        <Modal
          visible={tocVisible}
          footer={null}
          onCancel={() => setTocVisible(false)}
        >
          <div className="p-8 mx-auto">
            <h2 className="text-xl text-center text-teal-700 mb-4">Terms & Conditions</h2>
            <p className="mb-2 text-sm text-justify">
              <span className="font-bold">1. I am informed</span>
              <span> that my participation is entirely voluntary, and if I choose not to participate, there will be no negative effects on me. My sample will be given anonymous sample identification number. </span>
            </p>
            <p className="mb-2 text-sm text-justify">
              <span className="font-bold">2. Personal Information Privacy:</span>
              <span> I understand that my sample will be sent directly and anonymously to a third party independent lab for processing and that my results may be anonymously pooled into an epidemiology study. The results of these studies may be reported and published. However, under no circumstance will any of my personal information be made available or disclosed to any third party. </span>
            </p>
            <p className="mb-2 text-sm text-justify">
              <span className="font-bold">3. Use of Results with Public Health System and Medical Professionals:</span>
              <span> I understand that my test results may be provided to the site, public health system, regulatory system and appropriate professionals on a need to know basis and as directed by the trial regulation for medical devices. The purpose of this need to know sharing of my results is for the limited purpose of informing medical providers so they may advise me on appropriate medical treatment and for limiting the spread of communicable diseases. My personal information will not be shared with anyone else. </span>
            </p>
            <p className="mb-2 text-sm text-justify">
              <span className="font-bold">4. Assumption of Risk:</span>
              <span> I recognise that there are certain inherent risks associated with having my Saliva and blood sample drawn for analysis. I hereby consent for myself, my heirs, executors, administrators, assigns, or personal representatives, knowingly and voluntarily agree to have my sample drawn and analysed and hereby waive any and all rights, claims, or causes of action of any kind whatsoever arising out of my participation in this activity, and do hereby release and forever discharge Capital Health Services Private Limited, site and their affiliates, practitioners, managers, members, agents, attorneys, staff, volunteers, heirs, representatives, predecessors, successors and assigns. I am aware and understand that I should carry my own health insurance. </span>
            </p>
            <p className="mb-2 text-sm text-justify">
              <span className="font-bold">5. Indemnification:</span>
              <span> I agree to indemnify and hold harmless Capital Health Services India Private Limited, site and their affiliates, managers, members, agents, attorneys, staff, volunteers, heirs, representatives, predecessors, successors and assigns against any and all claims, suits, or actions of any kind whatsoever for liability, damages, compensation, or otherwise brought by me or anyone on my behalf, including attorney’s fees and any related costs, if litigation arises pursuant to any claims made by me or by anyone else acting on. my behalf. </span>
            </p>
            <p className="mb-2 text-sm text-justify">
              <span className="font-bold">6. DCGI Guidance</span>
              <span> I further understand that: </span>
            </p>
            <ul className="mb-2 text-sm list-disc list-inside text-justify">
              <li className="ml-2">
                This test has not been reviewed by the DCGI. 
              </li>
              <li className="ml-2">
                Negative results do not rule out SARS-CoV-2 infection, particularly in those who have been in contact with the virus. Follow-up testing with a molecular diagnostic (oral or nasal swab) should be considered to rule out infection in these individuals. 
              </li>
              <li className="ml-2">
                Results from antibody testing should not be used as the sole basis to diagnose or exclude SARS-CoV- 2 infection or to inform infection status. 
              </li>
              <li className="ml-2">
                Positive results may be due to past or present infection with non-SARS-CoV-2 coronavirus strains, such as coronavirus HKU1, NL63, OC43, or 229E. 
              </li>
            </ul>
            <p className="mb-2 text-sm text-justify">
              <span className="font-bold">7. Notification of Test Results:</span>
              <span> I understand that I will be contacted by site/Capital Health Services Private Limited to discuss all positive results. Negative results will be communicated with you using a variety of channels to include but not limited to: phone, mail, electronic/web- based delivery or other reasonable means. Negative results may be posted online using the anonymous sample identification number to expedite the notification process. </span>
            </p>
            &nbsp;
            <p className="mb-2 text-lg text-teal-700 text-justify font-bold">
              India Health Foundation
            </p>
            <p className="mb-2 text-sm font-bold text-justify">
              Capital Health Services Private Limited
            </p>
          </div>
        </Modal>
      <BackTop />
    </div>
  );
}
